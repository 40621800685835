<template>
<div fluid>
    <v-toolbar>
        <v-row align="center" dense>
         
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">

                <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on">
                        <h2>{{getMonthName()}}</h2>
                    </a>
                </template>
                <v-date-picker v-model="date" type="month" scrollable >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                        Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="change_period(date)">
                        OK
                    </v-btn>
                </v-date-picker>
            </v-dialog>
            <v-btn style="margin-left: 25px;margin-right: 4px"   class="my-1" fab dark small :color="'indigo'" @click="OpenInfos()">?</v-btn>
            <v-btn style="margin-right: 4px" v-if="can_create==true" class="my-1" fab dark small :color="'indigo'" @click="newplan()">+</v-btn>
           <div v-if="plan.date_from" style="text-align:center;"><h2> {{plan.date_from}} -  {{plan.date_to}} </h2></div>
            <v-spacer></v-spacer>
            <v-btn    style="padding: 4px" v-if="plan.isActif && current_operation.id > 0" @click.stop="action()" class="ma-1" color="blue darken-1">
                {{ current_operation.label }}
            </v-btn>
            <v-btn v-if="focalpoint && autorisations.findIndex((a) => a == '08003') >= 0"  style="padding: 4px" @click.stop="attribution(1)" class="ma-1" color="blue darken-1">
                Focal point
            </v-btn>
            <v-btn v-if="execution && autorisations.findIndex((a) => a == '04003') >= 0" style="padding: 4px" @click.stop="attribution(2)" class="ma-1" color="blue darken-1">
                Execution
            </v-btn> 
        </v-row>
    </v-toolbar>
    <listitems :title="'Tests List'" :list="data_list" :header_shoose="false" :headers="sel_headers"
     :table_height="'500'"
     :showstd="true" :showedit="false" :master="true" :key="cor_key" :add="false" :del="false" @rowselect="select_test" @col_btn1_click="WaiverOpen" :btnA_disa="true">
    </listitems>
    <waiter :showWait="showWait"> </waiter>
    <waiverform :dialog="waiver_dialog" :waivers="waivers" :plan="plan" :test="test_sel" :key="wkey" :can_create="
        autorisations.findIndex((a) => a == '03002') >= 0 &&
        plan.isActif == 1 &&
        plan.profile_selected == 0
      " :can_edit="
        autorisations.findIndex((a) => a == '03002') >= 0 &&
        plan.isActif == 1 &&
        plan.profile_selected == 0
      " :can_accept="
        autorisations.findIndex((a) => a == '04002') >= 0 &&
        plan.profile_selected == 0
      " :can_reject="
        autorisations.findIndex((a) => a == '06002') >= 0 &&
        plan.profile_selected == 0
      " @close_waiver="close_waiver"></waiverform>
    <attrform :tests="data_list" :dialog="attr_dialog" :users="agents" :key="katt" :operation="attr_operation" @close_attr="close_attr" :plan="plan" :att_fp="
        plan.isActif == 1 && 
        plan.is_validated == 1
      " :att_ex="
        plan.isActif == 1 && 
        plan.is_validated == 1
      "></attrform>
    <planinfo :key="kinfo" :dialog="infodialog" :infos="infos" :plan="plan" @CloseInfos="CloseInfos">
    </planinfo>
    <confirmdialog ref="confirm" />
    <confirmdialog ref="accept" />
</div>
</template>

 
<script>
import Data from "../graphql/Planning/Data.gql";
import TestsPlanSelect from "../graphql/Planning/TestPlanSelect.gql";
import TestsWaivers from "../graphql/Planning/TestWaivers.gql";
import TestsCreate from "../graphql/Planning/TestsCreate.gql";
import PlanAccept from "../graphql/Planning/PlanAccept.gql";
import PlanInfo from "../graphql/Planning/PlanningStatut.gql";
export default {
    components: {
        waiter: () => import("../components/Widgets/Waiter.vue"),
        confirmdialog: () => import("../components/ConfirmDialog.vue"),
        listitems: () => import("../components/ListItems.vue"),
        waiverform: () => import("../components/Waiver_List.vue"),
        attrform: () => import("../components/TestAttribut.vue"),
        planinfo: () => import("../components/PlanningInfo.vue"),
    },
    data() {
        return {
            focalpoint: false,
            execution: false,
            can_create: false,
            infos: [],
            kinfo: 100000,
            infodialog: false,
            wkey: 0,
            showSelect: 0,
            plan_min_date: null,
            cs: 10000,
            cor_key: 20000,
            cor_key2: 200000,
            katt: 300000,
            showWait: false,
            attr_operation: 1,
            waiver_dialog: false,
            attr_dialog: false,
            test_sel: {},
            agents: [],
            sel_headers:[],
            headers: [{
                    value: "operation",
                    selected: true,
                    slot: "chip",
                    list: ["PT", "AT", "R"],
                    width: "10",
                    plan:"'1'",
                    color:'color'
                },
                {
                    text: "Well",
                    value: "wellname",
                    selected: true,
                     plan:"'1'"
                },
                {
                    text: "Type",
                    value: "welltype",
                    selected: true,
                     plan:"'1'"
                },
                {
                    text: "Description",
                    value: "description",
                    selected: true,
                     plan:"'1'"
                },
                {
                    text: "Last Date",
                    value: "last_test",
                    selected: true,
                     plan:"'1'"
                },
                 {
                    text: "Over Due Date",
                    value: "overdue",
                    selected: true,
                     plan:"'1'"
                },
                 {
                    text: "Iteration",
                    value: "frequency",
                    selected: true,
                     plan:"'3'"
                },
                {
                    text: "Start Date",
                    value: "start_date",
                    selected: true,
                     plan:"'2'"
                },
                {
                    text: "Finish Date",
                    value: "end_date",
                    selected: true,
                     plan:"'2'"
                },
                {
                    text: "Next Due Date",
                    value: "due_date",
                    selected: true,
                     plan:"'1'"
                },
                {
                    text: "Waiver Alternative Date",
                    value: "waiver",
                    selected: true,
                     plan:"'2'"
                },
                {
                    text: "Waiver Count",
                    value: "waiver_count",
                    selected: true,
                     plan:"'1'"
                },
                {
                    text: "Waiver Task",
                    value: "waiver_task",
                    selected: true,
                     plan:"'2'"
                },
                {
                    text: "Edit Waiver",
                    slot: "col_btn1",
                    selected: true,
                    icon: "mdi-view-list",
                    width: "10",
                     plan:"'2'"
                },
            ],

            klist: 1,
            selbtn: 10000,
            tests_types: [],
            statuts: [],

            data_list: [], ///list de tests
            waivers: [], //list des waivers
            waiver: {},
            plan_list: [], //list des plan
            plan: {
                id: null,
                date_from: null,
                date_to: null,
                statut_id: null,
                periode: null,
            },

            autorisations: [],
            date: new Date().toISOString().substr(0, 7),
            start_date: "2024-11-01",
            modal: false,
        };
    },
    computed: {
  
        current_operation() { 
            let label = {
                id: -1,
                value: ""
            };
            //Accept
            if (
                this.plan.isActif == 1 &&
                this.plan.profile_selected == 0 &&
                this.plan.role_has_waiver == 0 &&
                this.plan.is_validated == 0 &&
                this.autorisations.findIndex((a) => a == "02002") >= 0
            )
                label = {
                    id: 1,
                    label: "Accept"
                };
            //validate
            else if (
                this.plan.isActif == 1 &&
                this.plan.all_selected == 1 &&
                this.plan.is_validated == 0 &&
                this.autorisations.findIndex((a) => a == "05002") >= 0
            )
                label = {
                    id: 2,
                    label: "Validate"
                };
            else label = {
                id: -1,
                value: ""
            };
            return label;
        },
    },
  async created() {
               const today = new Date();
        let d00 = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));

        this.start_date = d00.toISOString().split('T')[0];
        
        await this.getdata();
        this.cs++;
        this.autorisations = this.$store.state.me.autorisations.split(",");
        this.can_create = this.autorisations.findIndex((a) => a == "07002") >= 0;
        this.headers[10].selected = this.autorisations.findIndex((a) => a == "08002") >= 0;
        //01002 read
        //02002 accept or select
        //05002 validate
        //06002 att. fp---
        //07002 att. ex--
        //03002 Ask  Waiver
        //04002 Accept/Reject Waiver

      
    },
    methods: {
        getMonthName() {
            let monthNumber = this.date.split('-')[1];
            const date = new Date()
            date.setMonth(monthNumber - 1)
            return date.toLocaleString('en-US', {
                month: 'long'
            }) + ' ' + this.date.split('-')[0];
        },
        change_period(date) {
            this.$refs.dialog.save(date);
            this.start_date = (date + "-01");
            this.getplan();
        },
        async OpenInfos() {
            this.showWait = true;
            let r = await this.$requette(
                PlanInfo, {
                    plan_id: this.plan.id,
                },
                "no-cache"
            );
            if (r.ok) {
                this.infos = r.data.state_plannings;
                this.infodialog = true;
            }

            this.showWait = false;

            this.kinfo++;
        },
        CloseInfos() {
            this.infodialog = false;
        },
        newplan() {
            this.create_plan();
        },
 
        select_test(item) {
            this.test_sel = item;
        },

        action() {
            let id = this.current_operation.id;
            if (id == 1) this.select_plan(1);
            else if (id == 2) this.select_plan(2);
            if (id == 3) this.showattribution(1);
            if (id == 4) this.showattribution(2);
        },

        async select_plan(statut_id) {
            if (
                await this.$refs.accept.open(
                    "Confirmation",
                    +statut_id == 1 ?
                    "Accept current planning" :
                    "Validate  current planning", {
                        color: "orange darken-3"
                    }
                )
            ) {
                this.showWait = true;
                //  let v={id:this.plan.id,}
                let r = await this.$requette(
                    PlanAccept, {
                        plan: parseInt(this.plan.id),
                        statut_id: statut_id,
                        event_id: 2,
                        user_id: this.$store.state.me.id,
                    },
                    "no-cache"
                );
                if (r.ok) {
                    this.getplan();
                    this.showplan = false;
                }
                this.showWait = false;
            }
        },
        async create_plan() {
            // let dt_from = new Date();

            // dt_from.setMonth(dt_from.getMonth() + 1);
            // const today = new Date();
            // let d00 = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
            // let d11 = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0));

           // let d0 = d00.toISOString().split('T')[0];
            //let d1 = d11.toISOString().split('T')[0];

            if (this.plan) {  
                  if (
                      await this.$refs.confirm.open(
                          "Confirmation",
                          "Create new planning  from " + this.plan.next_date_from + " to " + this.plan.next_date_to, {
                              color: "orange darken-3"
                          }
                      )
                  ) {
                      this.save_plan(this.plan.next_date_from, this.plan.next_date_to);
                  }
                   
                  this.cs++;
                  this.showplan = true;
             }
        },
        async save_plan(d0, d1) {
            this.showWait = true;
            let r = await this.$requette(
                TestsCreate, {
                    user_id: this.$store.state.me.id,
                    date_from: d0,
                    date_to: d1,
                },
                "no-cache"
            );
            if (r.ok) {
               this.date= d0,
                this.start_date=d0;
                this.getplan();
            }
            this.showWait = false;
        },
        WaiverSelect(item) {
            this.waiver = item;
            this.event_headers[5].disabled = item.edit == 0;
        },
        async WaiverOpen(item) {
            this.showWait = true;
            let r = await this.$requette(
                TestsWaivers, {
                    test_id: item.id,
                    plan_id: this.plan.id,
                    user_id: this.$store.state.me.id,
                },
                "no-cache"
            );
            if (r.ok) {
                this.waivers = r.data.tests_events;

                this.waiver_dialog = true;
                this.wkey++;
            }
            this.showWait = false;
        },

        close_waiver(item) {
            if (item) {
                this.getplan();
            } else this.waiver_dialog = false;
        },
        close_attr(item) {
            if (item) {
                this.getplan();

            }
            this.attr_dialog = false;
            this.selbtn++;
        },
        attribution(operation) {
            this.attr_operation = operation;
            this.attr_dialog = true;
            this.katt++;
        },
        async getdata() {
            this.showWait = true;
            let r = await this.$requette(Data);
            if (r.ok) {
                this.agents = r.data.users;
                this.getplan();
            }

            this.showWait = false;
        },
        async getplan() {
            this.data_list = [];
            this.plan={};
            this.showWait = true;
            let r = await this.$requette(
                TestsPlanSelect, {
                    start_date: this.start_date,
                    user_id: this.$store.state.me.id,
                },
                "no-cache"
            );
            if (r.ok) {
                if (r.data.find_plan != null) {
                    if(r.data.find_plan.details && r.data.find_plan.details!=null) this.data_list = r.data.find_plan.details;
                    if(r.data.find_plan.details && r.data.find_plan.planning!=null) this.plan = r.data.find_plan.planning;
                }
                console.log(this.plan.id);
                if(this.plan.id>0)
                this.sel_headers=this.headers.filter(h=>h.plan=="'1'" || h.plan=="'2'"); else
                 this.sel_headers=this.headers.filter(h=>h.plan=="'1'" || h.plan=="'3'") 
             
                this.focalpoint = this.plan.is_validated == 1 && !this.plan.is_attributed2;
                this.execution = !this.plan.is_attributed1 && this.plan.is_attributed2;
                   this.cor_key++;
                this.cor_key2++; 
            }

            this.showWait = false;
        },

        search() {},
    },
};
</script>

<style scoped>
.center {
    text-align: center;
    background-color: #b8ababc0 !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    height: 30px;
    width: 200px;
}

.v-data-table>>>th {
    white-space: nowrap;
    border-bottom: none !important;
    /* border-right: 1px solid grey; */
}

.v-data-table>>>tr:hover {
    background: transparent !important;
}

.v-data-table>>>tr:nth-of-type(even) {
    background-color: transparent !important;
}

.v-data-table>>>tr:nth-of-type(odd) {
    background-color: transparent !important;
}

.v-data-table>>>td {
    border-bottom: none !important;
    /* border-right: 1px solid grey; */
}

.v-tcard,
.v-card:hover {
    background-color: #b8ababc0 !important;
    cursor: pointer;
}
</style>
